@import "../../../common/styles/Variables.scss";

.promotion-banner {
  position: relative;
  padding: 2rem;
  background-color: $color-primary;
  background-size: cover;
  background-position: center center;

  &--overlay {
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.1);
    inset: 0;
  }

  .promotion-column {
    position: relative;

    &--title {
      font-size: $font-size-xl;
      font-weight: $font-weight-bold;
      color: $color-white;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &--value {
      font-size: $font-size-4xl;
      font-weight: $font-weight-bold;
      color: $color-white;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
    }

    @media screen and (max-width: $screen-md) {
      text-align: center;
    }
  }
}
