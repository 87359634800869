@import "../../common/styles/Variables.scss";

.home-page {
  .standalone-ticket {
    img {
      height: 200px;
      width: auto;
      object-fit: contain;
    }
  }
}

.blogs-read-page {
  .blog-title {
    font-size: $font-size-3xl;
  }
  .ql-container {
    min-height: 200px;
    font-family: $font-family-normal;
    font-size: $font-size-md;
    border: 0 !important;

    .ql-editor {
      padding: 0 !important;
    }
  }
}

.contact-us-page {
  .contact-form {
    border: 5px solid transparent;
    transition: 0.3s all ease !important;
    max-width: 950px;
    margin: auto;
    border: 5px solid $color-primary;

    label {
      font-size: $font-size-md;
      color: $color-grey;
    }

    button.send-btn {
      padding: 0.8rem 1.6rem;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-lg;
      border: 2px solid $color-primary;
      outline: 0;
      color: $color-primary;
      border-radius: $border-radius-normal;
      background-color: transparent;
      text-transform: uppercase;
      transition: 0.3s all ease;

      &:hover {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
  .destination-card {
    position: relative;
    transition: all 0.4s ease-in-out;
    cursor: unset;

    .contact-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 2rem;

      li a {
        color: $color-grey;
        text-decoration: none;
        cursor: pointer !important;
      }
    }
  }
}

.view-destination-page {
  .gallery-image {
    position: relative;
    height: clamp(400px, 50vmin, 600px);
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
      .gallery-image__border-bottom-left,
      .gallery-image__border-right-top {
        width: 100%;
        height: 100%;
      }
    }

    &__border-bottom-left {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 0%;
      border-left: 2px solid $color-primary;
      border-bottom: 2px solid $color-primary;
      transition: all 0.4s ease-in-out;
    }
    &__border-right-top {
      position: absolute;
      top: 0;
      right: 0;
      width: 0%;
      height: 0%;
      border-right: 2px solid $color-primary;
      border-top: 2px solid $color-primary;
      transition: all 0.4s ease-in-out;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.sign-up-page,
.sign-in-page {
  height: 100vh !important;
  background-size: cover;
  background-position: center center;

  .su-p-h-100 {
    height: 100%;
  }

  .sign-up-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 60%;
    .header {
      text-align: left;
    }
    .upload {
      display: flex;
      justify-content: center;
      align-items: center;

      &--profile {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-2xl;
        font-weight: $font-weight-semi-bold;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: $color-muted;
        border: 2px solid $color-muted;
        box-shadow: 0 5px 20px rgba($color-primary, 0.8);
        cursor: pointer;
        transition: 0.3s all ease;
        &:hover {
          border-color: $color-primary;
          color: $color-primary;
        }
        input[type="file"] {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
        }
        .icon {
          position: absolute;
          color: $color-primary;
          background-color: $color-white;
          border-radius: 50%;
          height: 26px;
          width: 26px;
          bottom: -12px;
          right: 50%;
          transform: translateX(50%);
          z-index: 1;
        }
      }
    }
    form {
      width: 90%;
      max-width: 400px;
      max-height: 90vh;
      overflow-y: auto;

      .title {
        text-align: center;
        font-family: $font-family-normal;
        font-size: $font-size-xl;
        margin-bottom: 0px;
        position: relative;

        .back-to-website {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: $font-size-sm;
          font-weight: $font-weight-semi-bold;
          text-decoration: none;
          background-color: $color-muted;
          padding: 5px 10px;
          cursor: pointer;
          transition: 0.3s all ease-in-out;
          &:hover {
            color: $color-white;
            background-color: $color-link;
          }
        }
      }

      label {
        font-size: $font-size-sm;
      }
      input,
      select {
        font-size: $font-size;
      }

      .controls {
        .submit-btn {
          display: block;
          width: 100%;
          padding: 10px 20px;
          color: $color-white;
          font-size: $font-size-md;
          font-weight: $font-weight-semi-bold;
          border: 0;
          background-color: $color-primary;
        }
        .forgot-password-btn {
          display: block;
          font-family: $font-family-normal;
          font-size: $font-size;
          color: $color-grey;
          padding: 0;
          margin-top: 10px;
          margin-bottom: 10px;
          border: 0;
          background: transparent;
        }
        p.create-account,
        p.sign-in {
          color: $color-grey;
          display: block;
          font-size: $font-size;
          margin-top: 20px;
          margin-bottom: 10px;
          a {
            color: $color-link;
            font-weight: $font-weight-semi-bold;
          }
        }
      }
    }
  }
}
