@import "../../common/styles/Variables.scss";
@import "../../common/styles/Kit.scss";

.hash-tag {
  color: $color-primary;
}

.home-page {
  .quick-action-card {
    position: relative;
    cursor: pointer;
    &:hover .icon-show-onhover {
      scale: 1;
    }
    .icon-show-onhover {
      position: absolute;
      top: 15px;
      right: 15px;
      color: $color-grey;
      scale: 0;
      transition: all 0.3s ease;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      width: 54px;
      color: $color-white;
      background-color: $color-primary;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    .content {
      .title {
        font-family: $font-family-normal;
        font-size: $font-size-lg;
        font-weight: $font-weight-semi-bold;
        color: $color-primary;
        margin: 0;
        margin-bottom: 4px;
      }
      .description {
        font-size: $font-size-md;
        color: $color-grey;
        margin: 0;
      }
    }
  }
}

.raffle-page,
.affiliates-page {
  .dashboard-card {
    padding: 0;
    position: relative;

    &--positive {
      .top {
        background-color: #009967;
      }
      .title {
        color: #009967 !important;
      }
    }
    &--negative {
      .top {
        background-color: #b32453;
      }
      .title {
        color: #b32453 !important;
      }
    }
    &--neutral {
      .top {
        background-color: $color-primary;
      }
      .title {
        color: $color-primary !important;
      }
    }

    .top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      height: 54px;
      width: 54px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

      &.top-img-container {
        background-color: $color-white;
        img {
          position: absolute;
          height: 90% !important;
          width: 90% !important;
          object-fit: contain;
          -o-object-fit: contain;
          object-position: center;
          -o-object-position: center;
        }
      }

      .digit {
        font-family: $font-family-normal;
        color: $color-white;
        margin: 0;
      }
    }
    .content {
      padding: 1.2rem 1.6rem;

      .title {
        font-family: $font-family-normal;
        font-size: $font-size-lg;
        font-weight: $font-weight-semi-bold;
        color: $color-primary;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .description {
        font-size: $font-size-md;
        color: $color-grey;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
    .icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
      height: 60px;
      width: 60px;
      img {
        object-fit: contain;
      }
    }
  }
  .buy-ticket-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-black;

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      transition: 0.3s all ease;
      height: 40px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

      button {
        height: 100%;
        width: 100%;
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        color: $color-white;
        padding: 0.5rem 1rem;
        border: 0;
        background-color: $color-primary;
        cursor: pointer;
      }
    }
  }
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      margin: 0;
      &.title-go-back {
        cursor: pointer;
      }
    }
  }
  .table-container {
    table {
      width: 100%;
      .status {
        display: flex;
        width: fit-content;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        color: $color-white;
        padding: 0.2rem 0.8rem;
        &-positive {
          background-color: $color-success;
        }
        &-warning {
          background-color: $color-warning;
        }
        &-negative {
          background-color: $color-danger;
        }
      }

      td {
        button {
          white-space: nowrap;
          text-transform: capitalize;
        }
      }
    }
  }
}

.transaction-page {
  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .raffle-card {
    position: relative;
    background-color: $color-white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 500px !important;
    margin: auto;
    margin-top: 60px;
    .info-icon {
      position: absolute;
      margin: auto;
      height: 60px;
      width: 60px;
      right: 40px;
      top: 30px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        -o-object-fit: contain;
        object-position: center;
        -o-object-position: center;
      }
    }
    .title {
      font-size: $font-size-2xl;
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      margin-top: 10px;
    }
    &-label {
      margin: 0;
      margin-top: 20px;
    }
    &-value {
      font-size: $font-size-lg;
      font-weight: $font-weight-semi-bold;
      background-color: $color-muted;
      padding: 10px 20px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    &-error-msg {
      border: 2px solid $color-danger;
      color: $color-danger;
      background-color: #ffe3e1 !important;
    }

    &__footer {
      margin-top: 30px;
      text-align: center;
    }
  }
}

.checkout-page {
  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .order-summary-card {
    position: relative;
    background-color: $color-white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 500px !important;
    margin: auto;
    margin-top: 60px;
    .info-icon {
      position: absolute;
      margin: auto;
      height: 60px;
      width: 60px;
      right: 40px;
      top: 30px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        -o-object-fit: contain;
        object-position: center;
        -o-object-position: center;
      }
    }
    .title {
      font-size: $font-size-2xl;
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      margin-top: 10px;
    }

    .type-of-purchase {
      .label-for-purchase-type {
        line-height: 1;
        margin-bottom: 10px;
      }
      .options {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }

      label.purchase-type-option {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5px 10px;
        gap: 10px;
        width: fit-content;
        font-size: $font-size-lg;
        transition: 0.3s all ease;
        background-color: $color-muted;
        cursor: pointer;

        &--active {
          color: $color-white;
          background-color: $color-primary;
        }
        input {
          display: none;
        }
      }
    }

    label,
    p.label-for-purchase-type {
      font-size: $font-size;
      color: $color-grey;
    }
    input,
    select {
      font-size: $font-size-lg;
      border: 1px solid $color-border;
    }
    .footer {
      background-color: $color-muted;
      .space-between {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      .qty-price-container {
        margin-bottom: 10px;
        .tickets {
          font-size: $font-size-lg;
          font-family: $font-family-normal;
          font-weight: $font-weight-semi-bold;
          color: $color-dark;
          margin: 0;
        }
        .price {
          font-size: $font-size-lg;
          font-family: $font-family-normal;
          font-weight: $font-weight-semi-bold;
          text-align: right;
          margin: 0;
          span.label {
            display: block;
            font-size: $font-size;
            font-weight: $font-weight-normal;
            color: $color-grey;
          }
        }
      }

      .controls {
        margin-top: 30px;

        .terms-and-conditions {
          a {
            color: $color-dark;
            font-size: $font-size;
          }
        }
      }
    }
  }
}
