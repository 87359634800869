@import "./Variables.scss";

button {
  font-family: $font-family-normal;
  font-size: $font-size;
  cursor: pointer !important;

  &.view-btn {
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba($color: $color-primary, $alpha: 0.8);
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-primary, $alpha: 0.5);
    }
  }
}

.kit-btn {
  padding: 0.6rem 1rem;
  color: $color-white;
  outline: none;
  font-weight: $font-weight-normal;
  border: 0;
  text-transform: uppercase;
  background-color: $color-primary;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: rgba($color: $color-primary, $alpha: 0.8);
    box-shadow: 0 0.2rem 0.5rem rgba($color: $color-primary, $alpha: 0.5);
  }

  // Size
  &-sm {
    font-size: $font-size;
    padding: 0.5rem 0.8rem;
  }
  &-lg {
    font-size: $font-size-lg;
    padding: 0.8rem 1.6rem;
  }

  // Color
  &-primary {
    background-color: $color-primary !important;
    &:hover {
      background-color: rgba($color: $color-primary, $alpha: 0.8);
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-primary, $alpha: 0.5);
    }
  }
  &-success {
    background-color: $color-success !important;
    color: $color-white !important;
    &:hover {
      background-color: rgba($color: $color-success, $alpha: 0.8);
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-success, $alpha: 0.5);
    }
  }
  &-danger {
    background-color: $color-danger !important;
    color: $color-white !important;
    &:hover {
      background-color: rgba($color: $color-danger, $alpha: 0.8);
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-danger, $alpha: 0.5);
    }
  }
  &-muted {
    background-color: $color-muted !important;
    color: $color-dark !important;
    &:hover {
      background-color: rgba($color: $color-muted, $alpha: 0.8);
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-muted, $alpha: 0.5);
    }
  }

  // Inverse
  &-primary-inverse {
    background-color: $color-primary !important;
    border: 4px solid $color-primary !important;

    &:hover {
      background-color: transparent !important;
      color: $color-primary;
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-primary, $alpha: 0.5);
    }
  }
  &-success-inverse {
    background-color: $color-success !important;
    border: 4px solid $color-success !important;

    &:hover {
      background-color: transparent !important;
      color: $color-success;
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-success, $alpha: 0.5);
    }
  }
  &-danger-inverse {
    background-color: $color-danger !important;
    border: 4px solid $color-danger !important;

    &:hover {
      background-color: transparent !important;
      color: $color-danger;
      box-shadow: 0 0.2rem 0.5rem rgba($color: $color-danger, $alpha: 0.5);
    }
  }
}

.kit-btn-text {
  padding: 0.6rem 1rem;
  color: $color-primary;
  outline: none;
  font-weight: $font-weight-semi-bold;
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $color-white;
    background-color: rgba($color: $color-primary, $alpha: 0.8);
    box-shadow: 0 0.2rem 0.5rem rgba($color: $color-primary, $alpha: 0.5);
  }
}

.kit-btn-text-only {
  padding: 0;
  color: $color-primary;
  outline: none;
  font-weight: $font-weight-normal;
  font-size: $font-size-md;
  border: 0;
  text-transform: capitalize;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  .icon {
    transition: 0.3s ease all;
  }
  &:hover {
    .icon {
      margin-left: 10px;
    }
  }
}
