@import "../../../common/styles/Variables.scss";

$height: 70vh;

.carousel__slider {
  height: $height;
  overflow: hidden;

  ul {
    height: $height;

    li {
      height: $height;

      .text-overlay {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
        img {
          position: relative;
          height: 100px;
          width: 100%;
          object-fit: contain;
          object-position: center center;

          @media screen and (max-width: 500px) {
            height: 60px;
          }
        }
        h2 {
          font-size: $font-size-6xl;
          text-align: center;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
          color: $color-white;
          padding: 0;
          margin: 0;
          margin-top: 0.5rem;
          line-height: 1;

          @media screen and (max-width: 500px) {
            font-size: $font-size-3xl;
          }
        }
      }

      img.bg-image {
        height: $height;
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  .slider-control-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    transition: 0.3s background-color ease-in-out;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.6);
    }

    @media screen and (max-width: 959px) {
      width: 40px;
      height: 40px;
      svg {
        width: 30px;
        height: 30px;
      }
    }

    svg {
      width: 80px;
      height: 80px;
      fill: #fff;
      color: #fff;
    }
  }
}
