@import "../../../common/styles/Variables.scss";

.consortium-modal {
  .modal-header {
    display: block;

    .title {
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-2xl;
      margin-bottom: 10px;
    }
    .description {
      font-size: $font-size-md;
      color: $color-secondary;
      margin-top: 10px;
    }

    .tabs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: fit-content;

      list-style: none;
      margin: 0;
      margin-bottom: 30px;
      padding: 5px;

      border: 1px solid #eeeeee;

      .tab-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        gap: 5px;
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        transition: 0.3s ease all;

        &--active {
          background: #eeeeee;
          color: $color-black;
        }
      }
    }
  }

  .tab-header {
    &-title {
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-lg;
      margin-bottom: 10px;
    }
    &-description {
      font-size: $font-size-md;
      color: $color-secondary;
      margin-top: 10px;
    }
  }

  .membership-link {
    .share-description {
      text-align: center;
      font-size: $font-size-md;
      // font-weight: $font-weight-semi-bold;
      color: $color-grey;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .consortium-link-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: $color-muted 2px solid;
      background-color: $color-muted;
      padding: 5px;
      margin-bottom: 30px;

      .link {
        font-size: $font-size;
        font-weight: $font-weight-semi-bold;
        margin: 0;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .copy-btn {
        white-space: nowrap;
      }
    }
  }

  // Members form
  .consortium-members {
    .consortium-member-account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background-color: $color-light;
      border: 1px solid $color-muted;
      padding: 5px;

      &-name {
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        line-height: 1;
        margin: 0;
        padding: 10px;
      }
      button.delete-member {
        padding: 10px;
        color: $color-danger;
        border: 0;
      }
    }

    .consortium-member-email {
      &-form-controls {
        display: flex;
        gap: 10px;
        align-items: stretch;

        button.validate-member {
          white-space: nowrap;
          padding: 10px 15px;
          font-size: $font-size-sm;
          font-weight: $font-weight-semi-bold;
          background-color: $color-muted;
          border: 1px solid $color-muted;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    button {
      font-size: $font-size-sm;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .consortium-modal {
    .membership-link {
      .share-description {
        font-size: $font-size-md;
        color: $color-secondary;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .consortium-link-container {
        .link {
          font-size: $font-size-md;
        }
        .copy-btn {
          flex-shrink: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
