@import "../../common/styles/Variables.scss";

.main-layout {
  position: relative;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 150px;

  @media screen and (max-width: $screen-sm) {
    padding-bottom: 280px;
  }
}
