@import "../../../common/styles/Variables.scss";

.withdraw-history-modal {
  // Overide grid margin
  width: 800px !important;

  .title {
    font-size: $font-size-2xl;
    font-family: $font-family-normal;
    font-weight: $font-weight-semi-bold;
  }

  .dialog-content {
    max-height: 70vh !important;
    overflow-y: auto;
    position: relative;

    .kit-table-header {
      position: sticky;
      top: 0;
      background-color: $color-white;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .wstatus {
    font-size: $font-size-sm;
    text-align: center;
    text-transform: capitalize;

    &-pending {
      background-color: $color-link;
      color: $color-white;
    }
    &-verified {
      background-color: $color-warning;
      color: $color-white;
    }
    &-paid {
      background-color: $color-success;
      color: $color-white;
    }
    &-cancelled,
    &-expired,
    &-rejected {
      background-color: $color-danger;
      color: $color-white;
    }
  }
}
