@import "../../../common/styles/Variables.scss";

.raffle-quantity-modal {
  max-width: 450px !important;

  .title {
    font-size: $font-size-xl;
    font-family: $font-family-normal;
    font-weight: $font-weight-semi-bold;
  }

  .form {
    label {
      font-size: $font-size;
      color: $color-grey;
    }
    input {
      font-size: $font-size-md;
      font-weight: $font-weight-semi-bold;
      border: 1px solid $color-border;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    .qty-price-container {
      .price {
        font-size: $font-size-md;
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        margin: 0;
      }
      .tickets {
        font-size: $font-size;
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        color: $color-grey;
        margin: 0;
      }
    }
    button {
      padding: 1rem 1.5rem;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size;
    }
  }
}
