@import "../../../common/styles/Variables.scss";

.order-summary-modal {
  max-width: 450px !important;
  .title {
    font-size: $font-size-xl;
    font-family: $font-family-normal;
    font-weight: $font-weight-semi-bold;
  }

  .form {
    label {
      font-size: $font-size;
      color: $color-grey;
    }
    input {
      font-size: $font-size;
      font-weight: $font-weight-semi-bold;
      border: 1px solid $color-border;
    }
  }

  .footer {
    background-color: $color-muted;
    .space-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .qty-price-container {
      margin-bottom: 10px;
      .tickets {
        font-size: $font-size-md;
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        color: $color-dark;
        margin: 0;
      }
      .price {
        font-size: $font-size-md;
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        text-align: right;
        margin: 0;
        span.label {
          display: block;
          font-size: $font-size;
          font-weight: $font-weight-normal;
          color: $color-grey;
        }
      }
    }

    button {
      padding: 1rem 1.5rem;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size;
    }
  }
}
