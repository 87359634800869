@import "../../../common/styles/Variables.scss";

.blog-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 5px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: $color-primary;
    .title {
      color: $color-primary;
    }
  }

  &--content {
    .title {
      font-family: $font-family-base;
      font-size: $font-size-2xl;
      transition: all 0.3s ease;
    }

    .brief {
      font-size: $font-size-md;
    }
  }
}

.blog-editor {
}
