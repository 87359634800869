@import "../../styles/Variables.scss";
.snackbar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  visibility: visible;
  min-width: 300px;
  text-align: center;
  border-radius: $border-radius-sm;
  z-index: 1000;
  bottom: 30px;
  background-color: $color-success;
  color: $color-white;
  padding: 0.5em 0.8em;
  font-size: 1rem;
  font-weight: $font-weight-semi-bold;
  visibility: visible !important;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;

  &.default {
    background-color: $color-dark;
  }

  &.primary {
    background-color: $color-secondary;
  }

  &.success {
    background-color: $color-success;
  }

  &.warning {
    background-color: $color-warning;
  }

  &.danger {
    background-color: $color-danger;
  }

  .close-btn {
    border: 0;
    background-color: transparent;
    outline: none;
    color: $color-white;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}
