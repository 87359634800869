@import "../../../common/styles/Variables.scss";

.property-card {
  position: relative;
  padding: 1rem;
  border: 10px solid $color-dark;
  background-color: $color-white;
  cursor: pointer;
  transition: 0.3s all ease;
  overflow: hidden;
  background-color: inherit;
  outline: none;
  text-align: left;

  &:hover {
    border-color: $color-primary;
    .property-card--title {
      color: $color-primary;
    }
  }

  &--sticker {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 150px;
    height: 150px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translate(0%, -105px);
    background-color: $color-primary;
    p {
      font-family: $font-family-secondary;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
      font-family: $font-family-base;
      color: $color-black;
      letter-spacing: 0.2rem;
      margin: 0 0 0.5rem 0;
      max-width: 50px;
      text-align: center;
    }
  }
  &--location {
    font-family: $font-family-secondary;
    font-size: $font-size-2xl;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    transition: 0.3s all ease;
  }
  &--value {
    font-family: $font-family-secondary;
    font-size: $font-size-3xl;
    font-weight: $font-weight-semi-bold;
    color: $color-primary;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    transition: 0.3s all ease;
  }
  &--title {
    font-size: $font-size-4xl;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0.5rem;
    transition: 0.3s all ease;
  }
}
