@import "../../../common/styles/Variables.scss";

.section-content {
  position: relative;

  &--card {
    font-size: $font-size-xl;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 1.5rem;
    margin-top: 1rem;

    &--text-center {
      text-align: center;
    }
  }
}
