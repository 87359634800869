@import "../../../common/styles/Variables.scss";

.section-content {
  position: relative;

  &--paragraph {
    font-size: $font-size-xl;
    font-weight: $font-weight-semi-bold;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    &--text-center {
      text-align: center;
      margin: auto;
      max-width: 70%;

      @media screen and (max-width: $screen-md) {
        max-width: fit-content;
      }
    }
  }
}
