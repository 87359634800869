@import "./Variables.scss";

.kit-dropdown {
  padding: 0em;
  // border-radius: $border-radius-normal;

  .kit-dropdown-nav {
    padding: 0;

    li {
      margin: 0;
      &:not(:last-child) {
        border-bottom: 1px solid $color-muted;
      }

      .kit-dropdown-btn {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0.8em 1em;
        width: 100%;
        background-color: transparent;
        border: 0;
        outline: none;
        min-height: 0 !important;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        max-width: 200px;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $color-dark;

        span,
        .icon {
          min-width: 20px;
          margin-right: 5px;
        }

        &:hover {
          background-color: $color-hover;
          color: $color-black;
        }
      }
    }
  }
}
