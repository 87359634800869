@import "./Variables.scss";

.kit-table {
  width: 100%;
  border-spacing: 0 10px;

  &-header {
    th {
      font-size: $font-size-md;
      font-weight: $font-weight-semi-bold;
      text-align: left;
      padding: 0.5rem 1rem;
    }
  }
  &-row {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    td {
      font-size: $font-size-md;
      padding: 0.5rem 1rem;
      min-height: 25px;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .kit-table-header {
    display: none;
  }
  .kit-table-row td {
    text-align: right !important;
  }
  table {
    border: 0;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid $color-muted;
    display: block;
    font-size: 0.8em;
    & > * {
      margin-right: 0;
      margin-left: auto;
    }
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: $font-weight-semi-bold;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
