@import "../../../common/styles/Variables.scss";

.page-heading {
  position: relative;
  font-size: $font-size-3xl;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  &--section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.page-grid-heading {
  &--section {
    margin-top: 2rem;
    margin-bottom: 1rem;

    .page-heading-grid-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: $screen-sm) {
        align-items: center;
        text-align: center;
      }
    }

    .p-location {
      position: relative;
      font-size: $font-size-xl;
      font-weight: $font-weight-light;
      text-transform: uppercase;
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    .p-title {
      position: relative;
      font-size: $font-size-4xl;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: 0rem;
    }

    img {
      height: clamp(5rem, 20vmin, 500px);
      object-fit: contain;
    }
  }
}
