@import "./Variables.scss";
@import "./Card-Kit.scss";
@import "./Table-Kit.scss";
@import "./Button-Kit.scss";
@import "./Dropdown-Kit.scss";

// Modals
.custom-modal-style {
  //   .uk-modal-dialog {
  //     // border-radius: $border-radius;
  //   }

  .uk-modal-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-semi-bold;
  }

  .sub-heading {
    margin-bottom: 0.2em;
    text-transform: uppercase;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
}
