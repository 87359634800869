@import "../../../common/styles/Variables.scss";

.withdraw-modal {
  // Overide grid margin
  max-width: 500px !important;

  .uk-grid-margin {
    margin-top: 25px;
  }

  .title {
    font-size: $font-size-2xl;
    font-family: $font-family-normal;
    font-weight: $font-weight-semi-bold;
    // margin-top: 10px;
  }

  .current-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: $color-primary;
    .label {
      font-size: $font-size;
      color: $color-white;
      margin: 0;
    }
    .balance {
      font-size: $font-size-lg;
      font-weight: $font-weight-semi-bold;
      color: $color-white;
      margin: 0;
    }
  }

  span.amount-error {
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    color: $color-danger;
  }
  input.amount-input-error {
    transition: 0.3s all ease;
    border: 2px solid $color-danger !important;
    color: $color-danger;
  }

  .footer {
    .terms-and-conditions {
      a {
        color: $color-dark;
        font-size: $font-size;
      }
    }
  }
}
