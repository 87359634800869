@import "../../../common/styles/Variables.scss";

.document-card {
  position: relative;
  padding: 1rem;
  border: 10px solid $color-dark;
  background-color: $color-white;
  cursor: pointer;
  transition: 0.3s all ease;
  overflow: hidden;
  background-color: inherit;
  outline: none;
  text-align: left;

  &:hover {
    border-color: $color-primary;
    .document-card--title {
      color: $color-primary;
    }
  }
  &--doc-type {
    font-family: $font-family-secondary;
    font-size: $font-size-2xl;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    transition: 0.3s all ease;
  }
  &--title {
    font-size: $font-size-4xl;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 0.5rem;
    transition: 0.3s all ease;
  }
}
