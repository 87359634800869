@import "./common/styles/Variables.scss";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: $font-family-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-md;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-base;
}

a {
  font-family: $font-family-normal !important;
}

@media screen and (max-width: $screen-sm) {
  html,
  body {
    margin: 0;
    font-size: $font-size-sm !important;
    font-family: $font-family-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

input,
select,
textarea {
  font-size: $font-size;
  transition: 0.1s border ease;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $color-primary !important;
  }
}

// AOS
[data-aos] {
  pointer-events: none !important;
}
.aos-animate {
  pointer-events: auto !important;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset !important;
}
