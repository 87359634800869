@import "../../../common/styles/Variables.scss";

.affiliate-modal {
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      max-width: 70%;

      .title {
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-2xl;
        margin-bottom: 10px;
      }
      .description {
        font-size: $font-size-md;
        color: $color-secondary;
        margin-top: 10px;
      }
    }

    .right {
      img {
        height: 90px;
        width: 100px;
        object-fit: contain;
      }
    }
  }

  .affiliate-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: $color-muted 2px solid;
    background-color: $color-muted;
    padding: 5px;
    margin-bottom: 30px;

    .link {
      font-size: $font-size;
      font-weight: $font-weight-semi-bold;
      margin: 0;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .copy-btn {
      white-space: nowrap;
    }
  }

  .share-on-socials {
    .share-title {
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-lg;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    .share-description {
      font-size: $font-size-md;
      color: $color-secondary;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    .social-links {
      display: flex;
      flex-wrap: wrap;

      .social-link-item {
        margin-bottom: 15px;
        margin-right: 15px;
        a {
          display: flex;
          align-items: center;
          background-color: $color-muted;
          padding-right: 15px;
          text-decoration: none;
          line-height: 40px;
          color: $color-white;
          width: 220px;

          &.facebook-link {
            background-color: #1674ea;
            .icon {
              width: 40px;
              line-height: 40px;
              // background-color: darken($color: #1674ea, $amount: 10%);
            }
          }
          &.linkedin-link {
            background-color: #0c63bc;
            .icon {
              width: 40px;
              line-height: 40px;
            }
          }
          &.twitter-link {
            background-color: #1c9cea;
            .icon {
              width: 40px;
              line-height: 40px;
            }
          }
          &.whatsapp-link {
            background-color: #24cc63;
            .icon {
              width: 40px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  button.cancel-btn {
    padding: 0.5rem 1rem;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-lg;
    border: 0;
    outline: 0;
    border-radius: $border-radius-normal;
    background-color: transparent;
    color: $color-danger;
    transition: 0.3s all ease;

    &:hover {
      background-color: $color-danger;
      color: $color-white;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .affiliate-modal {
    .affiliate-link-container {
      .link {
        font-size: $font-size-md;
      }
      .copy-btn {
        flex-shrink: 0;
        white-space: nowrap;
      }
    }
    .share-on-socials {
      .share-title {
        font-family: $font-family-normal;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-lg;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      .share-description {
        font-size: $font-size-md;
        color: $color-secondary;
        margin-top: 5px;
        margin-bottom: 20px;
      }
      .social-links {
        flex-direction: column;
        .social-link-item {
          margin-right: 0 !important;
          a {
            justify-content: center;
            font-size: $font-size-md;
            width: 100%;
          }
        }
      }
    }
  }
}
