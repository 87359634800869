@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap");
@font-face {
  font-family: "BrightSight";
  src: local("BrightSight02"),
    url("../fonts/BrightSight/BRIGHTSIGHT02.otf") format("opentype");
  src: local("BrightSight02"),
    url("../fonts/BrightSight/BRIGHTSIGHT02.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Brother";
  src: local("Brother"),
    url("../fonts/BROTHER/BROTHER-Bold.otf") format("opentype");
  src: local("Brother"),
    url("../fonts/BROTHER/BROTHER-Bold.ttf") format("truetype");
  font-display: swap;
}

// fonts families
$font-family-base: "BROTHER", sans-serif;
$font-family-secondary: "BrightSight";
// $font-family-normal: "Roboto", sans-serif;
$font-family-normal: "Poppins", sans-serif;

// font sizes
$font-size-xs: 0.7rem;
$font-size-sm: 0.75rem;
$font-size: 0.85rem;
$font-size-md: 1rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;
$font-size-2xl: 1.75rem;
$font-size-3xl: 2.25rem;
$font-size-4xl: 3rem;
$font-size-5xl: 4rem;
$font-size-6xl: 5rem;
$font-size-7xl: 6rem;
$font-size-8xl: 7rem;

// font-weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// border-radius
$border-radius-sm: 0.2rem;
$border-radius-normal: 0.25rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 1rem;

// colors
$color-primary: #d2ae6d;
$color-secondary: #6c757d;
$color-success: #009967;
$color-danger: #dc3545;
$color-warning: #ffc107;
$color-info: #17a2b8;
$color-light: #f8f9fa;
$color-white: #fff;
$color-grey: #999;
$color-dark: #222;
$color-black: #000;
$color-muted: #f1f2f4;
$color-border: #ddd;
$color-hover: rgba(
  $color: $color-primary,
  $alpha: 0.2,
);
$color-link: #1e87f0;

// screen sizes
$screen-xs: 480px;
$screen-sm: 639px;
$screen-md: 959px;
$screen-lg: 1200px;
