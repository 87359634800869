@import "../../../common/styles/Variables.scss";

.advert-banner {
  // position: relative;
  // padding: 2rem;
  overflow: hidden;

  .advert-item {
    margin: 0 10px;
    a {
      img {
        height: 300px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
