@import "../../../common/styles/Variables.scss";

.consortium-members-modal {
  .modal-header {
    display: block;

    .title {
      font-family: $font-family-normal;
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-2xl;
      margin-bottom: 10px;
    }
    .description {
      font-size: $font-size-md;
      color: $color-secondary;
      margin-top: 10px;
    }
  }

  // Members form
  .consortium-members {
    .consortium-member-account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      background-color: $color-light;
      border: 1px solid $color-muted;
      padding: 5px;

      &-name {
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        line-height: 1;
        margin: 0;
        padding: 10px;
      }
      button.delete-member {
        padding: 10px;
        color: $color-danger;
        border: 0;
      }
    }

    .consortium-member-email {
      &-form-controls {
        display: flex;
        gap: 10px;
        align-items: stretch;

        button.validate-member {
          white-space: nowrap;
          padding: 10px 15px;
          font-size: $font-size-sm;
          font-weight: $font-weight-semi-bold;
          background-color: $color-muted;
          border: 0;
          transition: 0.3s all ease;

          &:hover {
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    button {
      font-size: $font-size-sm;
    }
  }
}
