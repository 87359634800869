@import "../../../common/styles/Variables.scss";

.navbar {
  &__top-nav {
    background-color: $color-light;

    &--container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    #nav-tel,
    #nav-mail {
      font-size: $font-size;
      color: $color-grey;
      margin-left: 1rem;
    }

    #nav-social-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-left: 0.5rem;
        margin-right: auto;
        a {
          color: $color-primary;
          font-size: $font-size-md;
          &.fb {
            color: #0573e7;
          }
          &.ig {
            color: #f500ac;
          }
          &.yt {
            color: #fd0000;
          }
        }
      }
    }
  }

  &__main {
    background-color: $color-white !important;
    box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.1);
    @media screen and (max-width: 959px) {
      padding: 15px;
    }

    .toggle-navbar-button {
      font-size: $font-size-md;
      display: block;
      position: relative;
      width: 25px;
      height: 25px;
      line-height: 25px;
      background-color: transparent;
      border: none;
      color: $color-primary;
      padding: 0;
    }

    #nav-brand {
      position: relative;
      min-height: 20px;
      height: 40px;
      width: auto;
      max-width: 150px;
      overflow: hidden;

      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
        width: 100%;
      }
    }

    .nav-item {
      a {
        color: $color-dark;
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        text-transform: none;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-primary;
        }
        &.active {
          color: $color-primary;
          // font-weight: $font-weight-bold;
        }
      }

      &--dropdown {
        background-color: $color-white;
        padding: 0;
        top: 75px;
        width: fit-content !important;

        a {
          color: $color-primary;
          padding: 0.5rem 1rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: $color-primary;
            font-weight: $font-weight-bold;
            text-decoration: underline;
          }
          &.active {
            color: $color-white;
            background-color: $color-primary;
            font-weight: $font-weight-bold;
          }
        }

        &--list {
          padding: 0;
          margin: 0;
        }
      }

      &--account {
        display: flex;
        justify-content: center;
        align-items: center;

        .profile {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          border: 0;
          padding: 5px 10px;
          &:hover {
            background-color: $color-hover;
          }

          .account-name {
            font-size: $font-size-md;
            font-weight: $font-weight-semi-bold;
            margin: 0 10px;
          }
          img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  &__off-canvas-parent {
    position: fixed;
    z-index: 1000;

    display: block !important;
    overflow: hidden;
    top: 0;
    width: 0;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &.show {
      width: 100% !important;
    }
  }

  &__offcanvas {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-dark;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    width: 220px;
    height: 100%;

    .nav-item {
      a {
        color: $color-white !important;
        font-size: $font-size-lg;
        font-weight: $font-weight-semi-bold;
        text-transform: none;
        transition: all 0.3s ease-in-out;
        padding: 10px;

        &:hover {
          color: $color-primary !important;
        }
        &.active {
          color: $color-primary !important;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}
