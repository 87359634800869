@import "../../../common/styles/Variables.scss";

.page-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;

  &__content {
    @media screen and (max-width: $screen-sm) {
      margin: 0;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $screen-sm) {
        padding: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
        // text-align: center;
        border-bottom: 2px solid $color-black;
        margin: 0px auto !important;
      }

      img {
        width: 100%;
        height: 90px;
        object-fit: contain;
      }

      .nav-links {
        list-style: none;
        margin: 0;
        padding: 0;
        .nav-link--item {
          margin-right: 1rem;
          a {
            color: $color-black;
            font-size: $font-size-md;
            font-weight: $font-weight-semi-bold;
            text-decoration: none;
            padding: 0.4rem 0.6rem;
            transition: all 0.3s ease-in-out;
            white-space: nowrap;
            @media screen and (max-width: $screen-sm) {
              white-space: normal !important;
            }
            &:hover {
              color: $color-white;
              background-color: rgba($color: $color-primary, $alpha: 0.8);
              box-shadow: 0 0.2rem 0.5rem
                rgba($color: $color-primary, $alpha: 0.5);
            }
          }
        }
      }

      .contact-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 0.5rem;
        padding: 0;
        list-style: none;

        .contact-link--item {
          &:not(:last-child) {
            margin-right: 1.4rem;
          }
          a {
            color: $color-black;
            font-size: $font-size-md;
            transition: all 0.3s ease-in-out;
            &:hover {
              font-weight: $font-weight-semi-bold;
            }
          }
        }
      }

      .social-links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        .social-link--item {
          margin-right: 1.2rem;
          a {
            color: $color-black;
            font-size: $font-size-xl;
            transition: color 0.3s ease;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      .copyrights {
        font-size: $font-size-md;
        text-decoration: none;
        color: $color-black;
      }
    }
  }
}
