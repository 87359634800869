@import "../../../common/styles/Variables.scss";

.info-bar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  flex-wrap: wrap;
  box-shadow: none !important;

  // Info bar types
  &--info {
    .type .icon {
      color: $color-primary !important;
    }
    .text {
      color: $color-grey;
    }
  }
  &--danger {
    .type .icon {
      color: $color-danger !important;
    }
    .text {
      color: $color-danger;
    }
  }

  &--success {
    .type .icon {
      color: $color-success !important;
    }
    .text {
      color: $color-grey;
    }
  }

  &--border {
    position: relative;
    background-color: $color-white;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
  }

  .type {
    min-width: 20px;
    .icon {
      font-size: $font-size-lg;
      color: $color-danger;
      vertical-align: middle;
    }
  }
  .text {
    font-size: $font-size-md;
    font-weight: $font-weight-normal;
    margin: 0;
  }
  .read-more-btn {
    font-size: $font-size-md;
    font-weight: $font-weight-semi-bold;
    color: $color-primary;
  }

  .close-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    background: $color-grey;
    height: 20px;
    width: 20px;
    color: $color-white;
    border: 0;
  }
}
